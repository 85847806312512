import { useMutation, gql } from "@apollo/client";

interface Payload {
  username: string;
  exp: number;
  origIat: number;
}

export interface TokenAuth {
  token: string;
  payload: Payload;
  refreshExpiresIn: number;
}

const GQL_TOKEN_AUTH = gql`
  mutation TokenAuth($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
      payload
      refreshExpiresIn
    }
  }
`;

interface TokenAuthData {
  tokenAuth: TokenAuth;
}

interface TokenAuthVars {
  username: string;
  password: string;
}

export const useTokenAuth = () =>
  useMutation<TokenAuthData, TokenAuthVars>(GQL_TOKEN_AUTH);

const GQL_VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`;

interface VertifyTokenData {
  payload: Payload;
}

interface VerifyTokenVars {
  token: string;
}

export const useVertifyToken = () =>
  useMutation<VertifyTokenData, VerifyTokenVars>(GQL_VERIFY_TOKEN);

const GQL_REFRESH_TOKEN = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      token
      payload
      refreshExpiresIn
    }
  }
`;

interface RefreshTokenData {
  refreshToken: TokenAuth;
}

interface RefreshTokenVars {
  token: string;
}

export const useRefreshToken = () =>
  useMutation<RefreshTokenData, RefreshTokenVars>(GQL_REFRESH_TOKEN);
