import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { ApolloProvider } from "@apollo/client";

import Login, { logout } from "./login/Login";
import { buildApolloClient, LoadingStatus } from "./common/Network";
import styles from "./App.module.css";

const Home = lazy(() => import("./home/Home"));
const BrowseApp = lazy(() => import("./browse/Browse"));
const EcologyApp = lazy(() => import("./ecology/Ecology"));
const ToxicologyApp = lazy(() => import("./toxicology/Toxicology"));
const SwatKmApp = lazy(() => import("./admin/toolbox/swat_km/Index"));

export default function App() {
  return (
    <ApolloProvider client={buildApolloClient()}>
      <Router>
        <AppHeader />
        <Container className={styles.appContent} fluid>
          <Suspense fallback={<LoadingStatus />}>
            <Login>
              <Switch>
                <Route path="/browse" component={BrowseApp} />
                <Route path="/ecology" component={EcologyApp} />
                <Route path="/toxicology" component={ToxicologyApp} />
                <Route path="/swat-km" component={SwatKmApp} />
                <Route path="*" component={Home} />
              </Switch>
            </Login>
          </Suspense>
        </Container>
      </Router>
    </ApolloProvider>
  );
}

function AppHeader() {
  return (
    <header className={styles.appHeader}>
      <h4>
        优先高关注化学品环境风险
        <br />
        精准评估动态管理软件平台
      </h4>
      <ul className={styles.appHeaderActions}>
        {/* TOOD: more actions here */}
        <li>
          <Switch>
            <Route exact path="/">
              <a href="#" onClick={(e) => logout()} role="button">
                退出登录
              </a>
            </Route>
            <Route>
              <Link to="/">返回主页</Link>
            </Route>
          </Switch>
        </li>
      </ul>
    </header>
  );
}
